<template>
    <!--详情页与审核页相同-->
    <div class="CheckDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="单据号" label-width="100px">
                                <span style="padding-left: 10px">{{ code }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构类型" label-width="100px">
                                <el-input v-model="form.deptTypeName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构名称" label-width="100px">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="创建人" label-width="100px">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建时间" label-width="100px">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="提交时间" label-width="100px">
                                <el-input v-model="form.updateTime" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="备注-盘点单" label-width="100px">
                                <el-input
                                    v-model="form.remark"
                                    type="textarea"
                                    style="width: 450px"
                                    :maxlength="200"
                                    :minlength="1"
                                    show-word-limit
                                    placeholder="字数不超过200"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="备注-盘点" label-width="100px">
                                <el-input
                                    v-model="form.checkRemark"
                                    type="textarea"
                                    style="width: 650px"
                                    :maxlength="200"
                                    :minlength="1"
                                    show-word-limit
                                    placeholder="字数不超过200"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="padding: 20px 10px">
                <el-button type="primary" @click="handleAudit(true)" v-if="form.reviewStatus == 0">审核 </el-button>
                <el-button type="primary" @click="handleAudit(false)" v-else>审核详情 </el-button>
                <el-dialog
                    title="审核业务单"
                    :visible.sync="reviewVisible"
                    width="30%"
                    :before-close="handleClose"
                    top="30vh"
                >
                    <div>
                        <el-form>
                            <el-form-item label="审核意见：">
                                <el-radio-group v-model="auditForm.advice">
                                    <el-radio label="1">通过</el-radio>
                                    <el-radio label="0">拒绝</el-radio>
                                    <el-radio label="1" v-if="advice == -1" v-model="auditForm.towAdvice"
                                        >反审核</el-radio
                                    >
                                    <el-radio label="1" v-if="advice == -2" v-model="auditForm.towAdvice"
                                        >驳回</el-radio
                                    >
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="审核备注：">
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 2 }"
                                    maxlength="200"
                                    show-word-limit
                                    placeholder="字数不超过200"
                                    v-model="auditForm.remark"
                                    style="width: 80%"
                                >
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="reviewVisible = false">取 消</el-button>
                        <el-button type="primary" @click="handleReview">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.details"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                v-loading="loading"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.bars" label="条码" width="200">
                    <template slot-scope="scope">
                        <span>{{
                            skuByGoodsCode(scope.row.goodsCode)
                                .bars.map((d) => d)
                                .join(' / ')
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="100">
                    <template slot-scope="scope">
                        {{ goodsByCode(scope.row.goodsCode).fastBar }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="120">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.category" label="类目" width="120">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.unit" label="单位" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.brand" label="品牌" width="120">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="firstCheckSnapshotCount"
                    label="初盘快照数量"
                    width="150"
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="初盘快照数量" width="200" trigger="click">
                                <p>提交盘点单时系统的库存数量</p>
                                <span slot="reference">初盘快照数量 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.firstCheckSnapshotCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="firstCheckStockChangeCount" label="初盘期间库存变动" width="170" sortable>
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="初盘期间库存变动" width="200" trigger="click">
                                <p>
                                    提交盘点单至提交初盘数据期间商品的累计出入库变动，初盘期间库存变动=累计入库数量-累计出库数量
                                </p>
                                <span slot="reference">初盘期间库存变动 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.firstCheckStockChangeCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="firstCheckCount" label="初盘数量" width="100">
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="初盘数量" width="200" trigger="click">
                                <p>初盘时录入的商品库存数量</p>
                                <span slot="reference">初盘数量 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.firstCheckCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="snapshotCounts"
                    label="初盘差异"
                    width="120"
                    sortable
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="初盘差异" width="200" trigger="click">
                                <p>初盘差异=初盘数量-初盘快照数量-初盘期间库存变动</p>
                                <span slot="reference">初盘差异 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.firstCheckDiffCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="snapshotCounts"
                    label="复盘快照数量"
                    width="140"
                    sortable
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="复盘快照数量" width="200" trigger="click">
                                <p>提交初盘数量时系统的库存数量</p>
                                <span slot="reference">复盘快照数量 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{
                            form.firstCheckSnapshotCounts[scope.$index] + form.firstCheckStockChangeCounts[scope.$index]
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="secondCheckStockChangeCount" label="复盘期间库存变动" width="170" sortable>
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="复盘期间库存变动" width="200" trigger="click">
                                <p>
                                    提交初盘数据后至提交复盘数据期间商品的累计出入库变动，复盘期间库存变动=累计入库数量-累计出库数量
                                </p>
                                <span slot="reference">复盘期间库存变动 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.secondCheckStockChangeCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column prop="secondCheckCount" label="复盘数量" width="100">
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="复盘数量" width="200" trigger="click">
                                <p>复盘时录入的商品库存数量</p>
                                <span slot="reference">复盘数量 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.secondCheckCounts[scope.$index] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="secondCheckDiffCount"
                    label="盈亏数量"
                    width="120"
                    sortable
                    v-if="hasPrivilege('biz.stock.query')"
                >
                    <template slot="header">
                        <span @click.stop style="display: inline-block">
                            <el-popover placement="top" title="盈亏数量" width="200" trigger="click">
                                <p>
                                    商品盘点数量与系统库存的差异，盈亏数量=复盘数量-复盘快照数量-复盘期间库存变动，未录入复盘数量时盈亏数量取初盘差异值
                                </p>
                                <span slot="reference">盈亏数量 <i class="el-icon-info"></i></span>
                            </el-popover>
                        </span>
                    </template>
                    <template slot-scope="scope">
                        {{ form.secondCheckDiffCounts[scope.$index] }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.stock.check.review'" />
    </div>
</template>
<script>
import StockBizCommon from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';
import PickupReturnAudit from '../../../components/PickupReturnAudit';

export default {
    name: 'CheckReview',
    components: {
        PickupReturnAudit,
    },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: this.$efApi.stockCheckApi.baseUrl,
            form: {
                code: '',
                advice: '1',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                remark: '',
                createTime: '',
                creator: '',
                reviewStatus: '',
                details: [],
                updateTime: '',
            },
            auditForm: {
                codes: [],
                advice: '1',
                towAdvice: '1',
                remark: null,
            },
            detailParamPropertyArr: ['codes', 'goodsCode', 'counts', 'snapshotCounts'],
            goodsDetailMap: new Map(),
            loading: true,
            reviewVisible: false,
            advice: 0,
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockCheckApi.detail(this.code);
            // Util.copyProperties(rst, this.form);
            this.form = rst;
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(rst, this.detailParamPropertyArr);
            this.form.details = arr.detailParams;
            this.goodsDetailMap = arr.goodsDetails;
            this.loading = false;
        })();
    },
    methods: {
        handleAudit(flag) {
            if (flag) {
                this.reviewVisible = true;
            } else {
                this.$refs.PickupReturnAudit.show(this.code, this.baseUrl);
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        handleClose() {
            this.reviewVisible = false;
        },
        async handleReview() {
            let rst;
            if (this.adviceNormal) {
                this.auditForm.codes = this.code;
                rst = await this.$efApi.reviewApi.review(this.baseUrl, this.auditForm);
                if (rst.status == 200) {
                    this.$message.success('审核成功');
                }
            } else if (this.advice == -1) {
                // 反审核
                rst = await this.$efApi.reviewApi.backReview(this.baseUrl, {
                    codes: this.form.codes,
                    bizCode: this.form.codes,
                    advice: '1',
                    remark: this.form.remark,
                });
                if (rst.status == 200) {
                    this.$message.success('反审核成功');
                }
            } else if (this.advice == -2) {
                //驳回
                rst = await this.$efApi.reviewApi.reject(this.baseUrl, {
                    codes: this.form.codes,
                    bizCode: this.form.codes,
                    advice: '1',
                    remark: this.form.remark,
                });
                if (rst.status == 200) {
                    this.$message.success('驳回成功');
                }
            }
            this.reviewVisible = false;
            this.goBackAndReload();
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
    computed: {
        adviceNormal() {
            return this.advice != -1 && this.advice != -2;
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
